var SortFilter;
(function (SortFilter) {
    SortFilter["Profit"] = "PROFIT";
    SortFilter["MaxDropDown"] = "MAX_DROP_DOWN";
    SortFilter["Copiers"] = "COPIERS";
    SortFilter["Price"] = "PRICE";
    SortFilter["ActiveDays"] = "ACTIVE_DAYS";
    SortFilter["FriendCopying"] = "FRIENDS_COPYING";
})(SortFilter || (SortFilter = {}));
var PeriodFiler;
(function (PeriodFiler) {
    PeriodFiler["7_DAYS"] = "7";
    PeriodFiler["30_DAYS"] = "30";
    PeriodFiler["90_DAYS"] = "90";
    PeriodFiler["180_DAYS"] = "180";
    PeriodFiler["AllPeriod"] = "ALL";
})(PeriodFiler || (PeriodFiler = {}));
var ExchangeFilter;
(function (ExchangeFilter) {
    ExchangeFilter["All"] = "ALL";
})(ExchangeFilter || (ExchangeFilter = {}));
const PERIOD_FILTER_OPTIONS = [
    { label: '7 Days', value: PeriodFiler['7_DAYS'] },
    { label: '30 Days', value: PeriodFiler['30_DAYS'] },
    { label: '90 Days', value: PeriodFiler['90_DAYS'] },
    { label: '180 Days', value: PeriodFiler['180_DAYS'] },
    { label: 'All time', value: PeriodFiler.AllPeriod },
];
const SORT_FILTER_OPTIONS = [
    { label: 'Profit', value: SortFilter.Profit },
    { label: 'Max Drop Down', value: SortFilter.MaxDropDown },
    { label: 'Copiers', value: SortFilter.Copiers },
    { label: 'Price', value: SortFilter.Price },
    { label: 'Active Days', value: SortFilter.ActiveDays },
    { label: 'Friends Copying', value: SortFilter.FriendCopying },
];
const EXCHANGE_FILTER_OPTIONS = [
    { label: 'All Exchanges', value: ExchangeFilter.All },
];
const SOCIALS_FILTER_OPTIONS = [
    { label: 'Instagram', value: 'INSTAGRAM' },
    { label: 'Telegram', value: 'TELEGRAM' },
    { label: 'YouTube', value: 'YOUTUBE' },
    { label: 'Web page', value: 'WEBPAGE' },
    { label: 'LinkedIn', value: 'LINKEDIN' },
    { label: 'Trading view', value: 'TRADING_VIEW' },
    { label: 'Discord', value: 'DISCORD' },
];
export { SortFilter, PeriodFiler, ExchangeFilter, SORT_FILTER_OPTIONS, PERIOD_FILTER_OPTIONS, EXCHANGE_FILTER_OPTIONS, SOCIALS_FILTER_OPTIONS, };

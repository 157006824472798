import { jsx as _jsx } from "react/jsx-runtime";
import { Trans } from 'react-i18next';
const OverviewTabTitle = () => Trans({ ns: 'common', i18nKey: 'pages.overview.overview' });
const PositionTabTitle = () => Trans({ i18nKey: 'portfolio.subscription.position' });
const HistoryTabTitle = () => Trans({ i18nKey: 'portfolio.history' });
const CopiersTabTitle = () => Trans({ i18nKey: 'portfolio.copiers' });
const PORTFOLIO_INFO_TABS = [
    {
        label: _jsx(OverviewTabTitle, {}),
        value: 'overview',
    },
    {
        label: _jsx(PositionTabTitle, {}),
        value: 'position',
    },
    {
        label: _jsx(HistoryTabTitle, {}),
        value: 'history',
    },
    {
        label: _jsx(CopiersTabTitle, {}),
        value: 'copiers',
    },
];
export default PORTFOLIO_INFO_TABS;

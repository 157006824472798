var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ReactComponent as FiltersIcon } from '@icons/wolfkit-light/filter-light.svg';
import { ReactComponent as XmarkIconComponent } from '@icons/wolfkit-light/xmark-light.svg';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
import CounterBadge from '@shared/ui/badges/CounterBadge';
import Button from '@shared/ui/buttons/Button';
const ButtonStyled = styled(ButtonCustom, {
    shouldForwardProp: propName => propName !== 'isActive',
})(props => ({
    color: props.isActive ?
        props.theme.palette.primary.main :
        props.theme.palette.text.secondary,
    borderColor: props.isActive ?
        props.theme.palette.primary.main :
        props.theme.customColors.input.border,
}));
const XmarkIcon = styled(XmarkIconComponent)(props => ({
    width: 16,
    height: 16,
    '& path': {
        fill: props.theme.palette.primary.main,
    },
}));
const FilterIcon = ({ isActive = undefined, }) => (isActive ? _jsx(XmarkIcon, {}) : _jsx(FiltersIcon, {}));
const FilterButtons = (_a) => {
    var { isActive = false, activeFiltersCount, handleFiltersClear = undefined } = _a, props = __rest(_a, ["isActive", "activeFiltersCount", "handleFiltersClear"]);
    const { t } = useTranslation();
    const showCounter = activeFiltersCount !== 0 && !isActive;
    return (_jsxs(_Fragment, { children: [_jsx(ButtonStyled
            // eslint-disable-next-line react/jsx-props-no-spreading
            , Object.assign({}, props, { isActive: isActive, startIcon: _jsx(FilterIcon, { isActive: isActive }), endIcon: showCounter && (_jsx(CounterBadge, { text: `${activeFiltersCount}`, fontSize: 12, minWidth: 18 })), variant: 'outlined', children: isActive ?
                    t('marketplace.all_portfolio_view.filters.show_filters_active_btn') :
                    t('marketplace.all_portfolio_view.filters.show_filters_btn') })), activeFiltersCount !== 0 && (_jsx(Button, { variant: 'tinted', onClick: handleFiltersClear, children: "Clear all" }))] }));
};
export default FilterButtons;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { ReactComponent as SquaresIcon } from '@icons/wolfkit-light/square-4-grid-light.svg';
import { ReactComponent as ListBulletIcon } from '@icons/wolfkit-light/list-bullet-light.svg';
import ButtonCustom from '@shared/ui/buttons/ButtonCustom';
const Container = styled.div((props) => ({
    display: 'flex',
    gap: `${props.theme.spacing_sizes.m}px`,
}));
const Button = styled(ButtonCustom, {
    shouldForwardProp: propName => propName !== 'isActive',
})((props) => ({
    border: `1px solid ${props.isActive ? props.theme.palette.primary.main :
        props.theme.palette.text.secondary}`,
    cursor: props.isActive ? 'default' : 'pointer',
    padding: `${props.theme.spacing_sizes.m}px`,
    '& path': {
        fill: props.isActive ? `${props.theme.palette.primary.main}` :
            `${props.theme.palette.text.secondary}`,
    },
    svg: {
        width: `${props.theme.spacing_sizes.xs * 2}px`,
        height: `${props.theme.spacing_sizes.xs * 2}px`,
    },
}));
const PortfolioViewButtons = ({ activeButton = 'card', onClick = undefined, }) => {
    const handleButtonClick = (viewType) => {
        if (onClick && viewType !== activeButton) {
            onClick(viewType);
        }
    };
    return (_jsxs(Container, { children: [_jsx(Button, { onClick: () => handleButtonClick('card'), isActive: activeButton === 'card', variant: 'pill', children: _jsx(SquaresIcon, {}) }), _jsx(Button, { onClick: () => handleButtonClick('list'), isActive: activeButton === 'list', variant: 'pill', children: _jsx(ListBulletIcon, {}) })] }));
};
export default PortfolioViewButtons;

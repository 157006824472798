var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import SelectComponent from '@shared/ui/inputs/Select';
const Select = styled(SelectComponent)((props) => ({
    gap: `${props.theme.spacing_sizes.xs * 1.25}px`,
    color: `${props.theme.palette.text.secondary}`,
}));
const Container = styled.div(() => ({
    cursor: 'pointer',
    width: 'inherit',
}));
const FilterSelect = (_a) => {
    var { options = [] } = _a, props = __rest(_a, ["options"]);
    const [open, setIsOpen] = useState(false);
    const handleOpen = () => {
        setIsOpen(true);
    };
    const handleClose = () => {
        setIsOpen(false);
    };
    return (_jsx(Container, { onClickCapture: handleOpen, children: _jsx(Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        , Object.assign({}, props, { open: open, onOpen: handleOpen, onClose: handleClose, children: options === null || options === void 0 ? void 0 : options.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) })) }));
};
export default FilterSelect;
